<template>
	<form method="POST" @submit.prevent="onSubmit">
		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('site.registration.last_name') }}</div>
					<div
						class="form__block--input"
						:class="[
							errorMessage.messages && errorMessage.messages.last_name ? 'input--error' : '',
						]"
					>
						<input
							type="text"
							class="input-linck"
							v-model="last_name"
							required=""
							name="last_name"
							value=""
							:placeholder="$t('site.registration.last_name')"
						/>
					</div>
					<div
						class="input-required"
						v-if="errorMessage.messages && errorMessage.messages.last_name"
					>
						<p v-for="(massage, massageInd) in errorMessage.messages.last_name" :key="massageInd">
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('site.registration.name') }}</div>
					<div
						class="form__block--input"
						:class="[
							errorMessage.messages && errorMessage.messages.first_name ? 'input--error' : '',
						]"
					>
						<input
							type="text"
							class="input-linck"
							v-model="first_name"
							required=""
							name="first_name"
							value=""
							:placeholder="$t('site.registration.name')"
						/>
					</div>
					<div
						class="input-required"
						v-if="errorMessage.messages && errorMessage.messages.first_name"
					>
						<p v-for="(massage, massageInd) in errorMessage.messages.first_name" :key="massageInd">
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('site.registration.middle_name') }}</div>
					<div
						class="form__block--input"
						:class="[
							errorMessage.messages && errorMessage.messages.middle_name ? 'input--error' : '',
						]"
					>
						<input
							type="text"
							class="input-linck"
							v-model="middle_name"
							required=""
							name="middle_name"
							value=""
							:placeholder="$t('site.registration.middle_name')"
						/>
					</div>
					<div
						class="input-required"
						v-if="errorMessage.messages && errorMessage.messages.middle_name"
					>
						<p v-for="(massage, massageInd) in errorMessage.messages.middle_name" :key="massageInd">
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('site.registration.name_company') }}</div>
					<div
						class="form__block--input"
						:class="[
							errorMessage.messages && errorMessage.messages.name_company ? 'input--error' : '',
						]"
					>
						<input
							type="text"
							class="input-linck"
							v-model="name_company"
							required=""
							name="name_company"
							value=""
							:placeholder="$t('site.registration.name_company')"
						/>
					</div>
					<div
						class="input-required"
						v-if="errorMessage.messages && errorMessage.messages.name_company"
					>
						<p
							v-for="(massage, massageInd) in errorMessage.messages.name_company"
							:key="massageInd"
						>
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('components.region') }}</div>
					<div
						class="form__block--input"
						:class="[
							errorMessage.messages && errorMessage.messages.region_id ? 'input--error' : '',
						]"
					>
						<region
							:class-list="['input-linck']"
							:modelValue="region_id"
							:name="'region_id'"
							:required="true"
							:multiple="true"
							:placeholder="$t('components.region')"
							v-on:update:modelValue="region_id = $event"
						/>
					</div>
					<div
						class="input-required"
						v-if="errorMessage.messages && errorMessage.messages.region_id"
					>
						<p v-for="(massage, massageInd) in errorMessage.messages.region_id" :key="massageInd">
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('site.registration.email') }}</div>
					<div
						class="form__block--input"
						:class="[errorMessage.messages && errorMessage.messages.email ? 'input--error' : '']"
					>
						<input
							type="email"
							class="input-linck"
							v-model="email"
							required=""
							name="email"
							value=""
							:placeholder="$t('site.registration.email')"
						/>
					</div>
					<div class="input-required" v-if="errorMessage.messages && errorMessage.messages.email">
						<p v-for="(massage, massageInd) in errorMessage.messages.email" :key="massageInd">
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line form__line--minmargin">
			<div class="form__line--right">
				<div class="form__line--input">
					<div class="form__line--title">{{ $t('site.registration.phone') }}</div>
					<div
						class="form__block--input"
						:class="[errorMessage.messages && errorMessage.messages.phone ? 'input--error' : '']"
					>
						<TheMask
							:value="phone"
							:placeholder="'+7 (_ _ _)  _ _ _  _ _  _ _'"
							:mask="['+7 (###) ### ## ##']"
							:masked="true"
							name="phone"
							type="tel"
							class="input-linck"
							v-on:input="phone = $event"
						/>
					</div>
					<div class="input-required" v-if="errorMessage.messages && errorMessage.messages.phone">
						<p v-for="(massage, massageInd) in errorMessage.messages.phone" :key="massageInd">
							{{ massage }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="form__line">
			<div class="form__line--right">
				<button class="input-btn form__line--btn">
					{{ $t('button.save') }}
				</button>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-12">
				<div
					v-if="message.text"
					:class="{
						'form__messages--error': message.status != 200,
						'form__messages--success': message.status == 200,
					}"
					class="form__block--line form__messages mb-5"
				>
					<label class="form__block--input">
						<span class="form__block--title">
							{{ message.text }}
						</span>
					</label>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex'

import { api } from '@/boot/axios'
import { TheMask } from 'vue-the-mask'

import region from '@/components/components/Inputs/region.vue'

export default {
	name: 'ProfileEdit',
	components: {
		region,
		TheMask,
	},
	data() {
		return {
			last_name: '',
			first_name: '',
			middle_name: '',
			region_id: null,
			name_company: '',
			email: '',
			phone: null,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	computed: {
		...mapGetters(['_getUserObject', '_getRegionList']),
	},
	watch: {
		_getUserObject: {
			handler() {
				this.setData()
			},
			immediate: true,
		},
	},
	methods: {
		onSubmit() {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}

			const formData = new FormData()

			if (this.first_name) formData.append('first_name', this.first_name)
			if (this.last_name) formData.append('last_name', this.last_name)
			if (this.middle_name) formData.append('middle_name', this.middle_name)
			if (this.name_company) formData.append('name_company', this.name_company)
			if (this.email) formData.append('email', this.email)
			if (this.phone) formData.append('phone', this.phone.replaceAll(/\D/g, ''))

			if (this.region_id?.length) {
				this.region_id.forEach((region) => {
					formData.append(`regions[]`, region)
				})
			}

			api
				.post('user/update', formData)
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						const newRegions = []
						this.region_id.forEach((rId) => {
							const region = this._getRegionList.find((region) => region.id === rId)

							if (region)
								newRegions.push({
									...region,
									region_id: region.id,
								})
						})

						this.$store.commit('SET_USER_OBJECT', {
							...this._getUserObject,
							last_name: this.last_name,
							first_name: this.first_name,
							middle_name: this.middle_name,
							name_company: this.name_company,
							email: this.email,
							phone: this.phone,
							regions: newRegions,
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
						this.message.status = 422
						this.message.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
		setData() {
			if (this._getUserObject) {
				this.last_name = this._getUserObject.last_name
				this.first_name = this._getUserObject.first_name
				this.middle_name = this._getUserObject.middle_name
				this.region_id = this._getUserObject.regions.map((region) => region.region_id)
				this.name_company = this._getUserObject.name_company
				this.email = this._getUserObject.email
				this.phone = this._getUserObject.phone
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
